<template>
  <div class="flex flex-col min-h-screen bg-gray-100">
    <div class="flex h-16 bg-gray-200 shadow">
      <div class="flex items-center justify-between page-container">
        <div class="flex items-center">
          <Logo class="w-10 h-10 p-1 bg-red-500 rounded" />
          <h1 class="ml-4 text-gray-800 text-md">{{ title }}</h1>
        </div>
        <div>
          <slot name="header"></slot>
        </div>
      </div>
    </div>
    <div class="page-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Logo from "../components/Logo";

export default {
  components: { Logo },
  props: ["title"],
};
</script>
