<template>
  <PageLayout title="Questions">
    <div class="flex flex-col items-center my-4 space-y-4">
      <Card class="w-full">
        <Messages />
      </Card>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "../layouts/PageLayout";
import Messages from "../components/Messages";
import Card from "../components/shared/Card";

export default {
  components: { Messages, PageLayout, Card },
};
</script>
