<template>
  <svg viewBox="0 0 164 234" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M151.408 5C4.94142 77.6578 -10.7866 168.691 60.4111 186.418C94.3943 194.879 129.782 166.176 129.782 135.784"
      stroke="white"
      stroke-width="10"
    />
    <path
      d="M129.161 128.65C119.366 37.7007 140.028 38.1494 160.775 25.9489"
      stroke="white"
      stroke-width="10"
    />
    <path
      d="M5 153.175C12.8873 219.088 128.239 223.686 140.563 153.175"
      stroke="white"
      stroke-width="10"
    />
    <line
      x1="82.3943"
      y1="125.15"
      x2="126.761"
      y2="125.15"
      stroke="white"
      stroke-width="7"
    />
    <line
      x1="82.3943"
      y1="113.909"
      x2="124.789"
      y2="113.909"
      stroke="white"
      stroke-width="7"
    />
    <path d="M79.4366 128.65V110.255" stroke="white" stroke-width="7" />
    <line
      x1="77.4648"
      y1="169.091"
      x2="118.873"
      y2="169.091"
      stroke="white"
      stroke-width="7"
    />
    <line
      x1="75.493"
      y1="158.35"
      x2="121.831"
      y2="158.35"
      stroke="white"
      stroke-width="6"
    />
    <line
      y1="-3.5"
      x2="12.2628"
      y2="-3.5"
      transform="matrix(0.00550474 -0.999985 0.999983 0.00591387 82.3943 172.591)"
      stroke="white"
      stroke-width="7"
    />
    <line
      x1="77.4648"
      y1="147.631"
      x2="126.761"
      y2="147.631"
      stroke="white"
      stroke-width="7"
    />
    <line
      x1="82.3943"
      y1="136.391"
      x2="128.732"
      y2="136.391"
      stroke="white"
      stroke-width="7"
    />
    <path d="M79.4366 151.131V132.737" stroke="white" stroke-width="7" />
    <line
      x1="74.5775"
      y1="206.314"
      x2="74.5775"
      y2="233.905"
      stroke="white"
      stroke-width="10"
    />
    <line
      x1="33.0986"
      y1="228.905"
      x2="111.972"
      y2="228.905"
      stroke="white"
      stroke-width="10"
    />
    <path
      d="M127.746 116.898C127.746 126.095 132.676 136.314 127.254 148.577"
      stroke="white"
      stroke-width="10"
    />
  </svg>
</template>
