<template>
  <ul class="flex flex-col space-y-1 overflow-y-auto bg-gray-100 rounded h-96">
    <div
      v-if="messages.length === 0"
      class="flex items-center justify-center h-full"
    >
      <Spinner class="text-red-500 icon-lg" />
    </div>
    <li
      v-else
      v-for="message in messages"
      :key="message.id"
      class="flex items-center px-4 py-1"
    >
      <button type="button" @click="copyToClipboard(message.id)" class="group">
        <DuplicateIcon class="w-4 icon-action" />
      </button>
      <div class="ml-2 text-xs text-gray-500">
        {{ message.name || "unknown" }}:
      </div>
      <div class="ml-1">
        {{ message.text }}
      </div>
    </li>
  </ul>
</template>

<script>
import { onBeforeUnmount, ref } from "vue";
import { db } from "../firebase";
import Spinner from "./shared/Spinner";
import { DuplicateIcon } from "./icons";

export default {
  components: { Spinner, DuplicateIcon },
  setup() {
    const messages = ref([]);

    const unsubscribeMessages = db
      .collection("chat")
      .orderBy("createdAt", "desc")
      .limit(30)
      .onSnapshot(snapshot => {
        messages.value = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
      });

    onBeforeUnmount(unsubscribeMessages);

    const copyToClipboard = async messageId => {
      const message = messages.value.find(m => m.id === messageId);
      const text = `[${message.name}]: ${message.text}`;

      await navigator.clipboard.writeText(text);
    };

    return {
      messages,
      copyToClipboard,
    };
  },
};
</script>
